<template>
  <base-view title="Estado Analítico del Ejercicio del Presupuesto de Egresos por Fuente de Financiamiento-Proyecto-Unidad Administrativa-Partida">
    <div class="container-fluid mt-4">
      <div class="card">
        <div class="card-body">
          <reporte-analitico-ppto-egresos/>
        </div>
      </div>
    </div>
  </base-view>
</template>

<script>
import ReporteAnaliticoPptoEgresos from '@/components/Reportes/ReporteAnaliticoPptoEgresos/ReporteAnaliticoPptoEgresos'

export default {
  name: 'ReporteAnaliticoPptoEgresosView',

  components: {
    ReporteAnaliticoPptoEgresos
  }
}
</script>
