<template>
  <div>
    <div v-if="data.value === ''"></div>
    <div v-else-if="data.item.nivel === 1 && data.value >= 0" class="text-black text-md font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
    <div v-else-if="data.item.nivel === 1 && data.value <  0" class="text-danger text-md font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
    <div v-else-if="data.item.nivel === 2 && data.value >= 0" class="text-black-75 text-md font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
    <div v-else-if="data.item.nivel === 2 && data.value <  0" class="text-danger text-md font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
    <div v-else-if="data.item.nivel === 3 && data.value >= 0" class="text-black-75 text-sm font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
    <div v-else-if="data.item.nivel === 3 && data.value <  0" class="text-danger text-sm font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
    <div v-else-if="data.item.nivel === 4 && data.value >= 0" class="text-black-50 text-sm float-right" >{{ $formatNumber(data.value) }}</div>
    <div v-else-if="data.item.nivel === 4 && data.value <  0" class="text-danger text-sm float-right" >{{ $formatNumber(data.value) }}</div>
  </div>
</template>

<script>
export default {
  name: 'ReporteAnaliticoPptoEgresosCurrencyRow',

  props: {
    data: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
